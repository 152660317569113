import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Title from "../components/title"
import { CardContainer } from "../components/card"
import { SpecialOfferAccordion } from "../components/Accordion"
import SEO from "../components/SEO/seo"

const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25%;
  grid-gap: 40px;
  justify-content: center;
  margin-bottom: 80px;
  @media (max-width: 800px) {
    grid-template-columns: 42% 42%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 80%;
    grid-gap: 20px;
  }
`
const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 62.5%;
  position: relative;
  height: 0;
`
const StyledIframe = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`
const PriceContentContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 35%;
  justify-content: space-evenly;
  margin-bottom: 100px;
  @media (max-width: 800px) {
    grid-template-columns: 46% 41%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 80%;
    grid-row-gap: 20px;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    padding: 0 20px;
    margin: 60px 0;
  }
`
// const TextContainer = styled.div`
//   margin-top: 6vw;
//   display: grid;
//   grid-template-columns: 80%;
//   align-content: start;
//   justify-content: center;
//   @media (max-width: 1200px) {
//     grid-template-columns: auto;
//   }
//   @media (max-width: 767px) {
//     margin-top: 25px;
//     grid-template-columns: 60%;
//     text-align: center;
//   }
//   @media (max-width: 575px) {
//     grid-template-columns: auto;
//     padding: 0 20px;
//   }
// `
const ContentH2 = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #33475b;
  @media (max-width: 1450px) {
    font-size: 20px;
  }
`

// const ContentP = styled.div`
//   font-size: 19px;
//   color: #33475b;
//   margin: 15px 0;
//   @media (max-width: 1450px) {
//     font-size: 17px;
//     color: rgba(22, 31, 50, 0.7);
//     line-height: 1.6;
//   }
// `
const ContentButtonGrid = styled.div`
  display: grid;
  padding-top: 20px;
  grid-template-columns: 150px;
  justify-content: start;
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const StyledDontWorryContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  column-gap: 30px;
  padding: 30px 15px;
  margin: 80px 0;
  z-index: -2;

  @media (max-width: 767px) {
    grid-template-columns: auto;
  }
  @media (max-width: 575px) {
    column-gap: 10px;
  }
`
const PayementOptionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  align-content: center;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`

const StyledNotWorryContent = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(47, 57, 78, 1);
  justify-content: center;
  @media (max-width: 767px) {
    grid-column: 1 / span 2;
    text-align: center;
  }
  @media (max-width: 350px) {
    grid-column: 1 / span 1;
  }
`
const CountDownContainer = styled.div`
  display: grid;
  background-color: #33cdd1;
  color: white;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;
  padding: 50px 0;
  margin: 80px 0;
`
const CountDownGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
`
const CountDownTime = styled.div`
  font-size: 65px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 575px) {
    font-size: 40px;
  }
`
const CountDownText = styled.div`
  font-size: 17px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
`

const StyledNotWorryContentTitle = styled.p`
  font-size: 36px;
  margin-bottom: 10px;
`
const StyledNotWorryContentText = styled.p`
  font-size: 20px;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: rgba(22, 31, 50, 0.7);
`

// ----- TESTIMONIAL -----

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 36% 45%;
  align-content: center;
  justify-content: space-evenly;
  margin: 60px 0 100px 0;
  font-family: "Montserrat", sans-serif;
  grid-column-gap: 40px;

  @media (max-width: 960px) {
    grid-template-columns: 40% 55%;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`
const ImageSection = styled.div`
  display: grid;
  grid-template-columns: 55%;
  justify-content: center;
  align-content: center;
  @media (max-width: 960px) {
    grid-template-columns: 85%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 60%;
    /* grid-area : 2 / 1; */
  }
`
const TextContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 75%;
  font-family: "Montserrat", sans-serif !important;
  @media (max-width: 767px) {
    justify-content: center;
    margin-top: 25px;
    grid-template-columns: 60%;
    text-align: center;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
    grid-template-columns: auto;
  }
`
const ContentP = styled.p`
  font-size: 16px;
  color: #3d4d69;
  margin: 15px 0;
  line-height: 1.5;
  letter-spacing: 0.3px;
`
const PersonName = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #2f394e;
  margin-top: 30px;
  margin-bottom: 0;
`
const StyledA = styled.a`
  text-decoration: none;
  color: #008faa;
`

// ----- NEW DESIGN -----
const ColoredSection = styled.div`
  padding: 25px 15px;
  text-align: center;
  color: white;
  font-size: 18px;
  background-color: #33475b;
  margin: 30px 0;
  @media (max-width: 1450px) {
    font-size: 17px;
  }
`
const CouponTitle = styled.p`
  padding: 1.8em;
  text-align: center;
  font-size: 24px;
  color: #33475b;
`
const CouponCode = styled.span`
  color: #33cbcf;
  font-weight: 700;
`
const CouponContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-gap: 20px 5%;
  padding: 15px;
  margin-bottom: 7vh;
  @media (max-width: 600px) {
    grid-template-columns: auto;
  }
`
const CouponButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  grid-column-gap: 15px;
  padding: 15px 0;
`
const CouponButton = styled.div`
  padding: 18px 20px;
  color: ${props => (props.light ? `#33475B` : `white`)};
  background-color: ${props => (props.light ? `white` : `#33CBCF`)};
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  @media (max-width: 1450px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`
const CouponText = styled.div`
  color: #33475b;
  font-size: 18px;
  @media (max-width: 1450px) {
    font-size: 17px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`
const DollerBold = styled.span`
  font-size: 24px;
  font-weight: bold;
  @media (max-width: 1450px) {
    font-size: 23px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
  }
`
const HomeAnimateContainer = styled.div`
  display: grid;
  grid-template-columns: 600px;
  justify-content: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 15px;
  }
`
const HomeImageFlex = styled.div`
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: ${props => (props.imageFlex === "a" ? `70%` : `100%`)};
`
const buttonRipple = keyframes`
  from {
    border: 0px solid rgba(51, 205, 209, 0.4);
  }
  to {
    border: 20px solid rgba(51, 205, 209, 0);
  }
`
const AnimateButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: ${buttonRipple} 2s ease infinite;
  padding: 33px;
`

const HomeVideoButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: grid;
  justify-content: center;
  align-content: center;
  background: white;
  padding: 15px;
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
`
const PlayIcon = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 28px solid #33cdd1;
  margin-left: 8px;
`
const VideoResponsiveGrid = styled.div`
  display: grid;
  align-content: center;
`

const TestimonialIndex = props => {
  const [testiText, settestiText] = useState([
    {
      id: 0,
      textP1: `If you don't have an audience, you don't have a business. A Facebook Group is the EASIEST and FASTEST way for you to start generating free leads and building a tribe. Groupboss enables you to EFFORTLESSLY add your group members email details to your database at the click of a button. Whilst there may be similar products in the marketplace, not one of them compares with Groupboss.`,
      personName: `Mark Bowness`,
      personPos: `Founder of `,
      personCompany: `We Build Tribes`,
      personLink: `https://markbowness.com.au/`,
    },
  ])
  return (
    <>
      <ContentContainer>
        <ImageSection>
          <div>{props.client}</div>
        </ImageSection>
        <TextContainer>
          <div>
            {props.quote}
            {/* <ContentH3>{testiText[0].textH3}</ContentH3> */}
            {testiText[0].textP1 !== "" && (
              <ContentP>{testiText[0].textP1} </ContentP>
            )}
            {testiText[0].textP2 !== "" && (
              <ContentP>{testiText[0].textP2} </ContentP>
            )}
            <PersonName>{testiText[0].personName}</PersonName>
            <ContentP style={{ marginTop: `5px` }}>
              {testiText[0].personPos}
              <StyledA
                target="_blank"
                rel="noopener noreferrer"
                href={`${testiText[0].personLink}`}
              >
                {testiText[0].personCompany}
              </StyledA>
            </ContentP>
          </div>
        </TextContainer>
      </ContentContainer>
    </>
  )
}

export default ({ data, location }) => {
  const [videoShow, setVideoShow] = useState(false)
  const videoSelector = () => {
    setVideoShow(true)
  }

  return (
    <>
      <SEO
        title="Special Offer for the Grouplauncher tribe by Mark Bowness"
        description="Grow your business by collecting unlimited leads from your Facebook groups. Use Groupboss to build a solid community and collect unlimited leads."
        pathname={location.pathname}
      />

      <Title
        main
        fontSize="36px"
        titleText="Hey Group Launchers"
        titleSub="Thanks a lot for checking out Groupboss. Use Groupboss to build a strong community and generate unlimited leads from your Facebook Group."
      />

      <HomeAnimateContainer>
        {videoShow === false ? (
          <HomeImageFlex>
            <GatsbyImage
              image={data.indexHome.childImageSharp.gatsbyImageData}
              loading="eager"
              alt="video paly thumbnail"
            />
            <AnimateButton />
            <HomeVideoButton onClick={() => videoSelector()}>
              <PlayIcon />
            </HomeVideoButton>
          </HomeImageFlex>
        ) : (
          <VideoResponsiveGrid>
            <VideoResponsive>
              <StyledIframe
                src="https://www.youtube.com/embed/I9wujIoKZQc"
                title="Collect Emails from your facebook groups and grow email lists like a champ"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </VideoResponsive>
          </VideoResponsiveGrid>
        )}
      </HomeAnimateContainer>

      <ColoredSection>
        Team Groupboss has planned to give a special discount for the Tribe of
        Mark Bowness
      </ColoredSection>
      <CouponTitle>
        Apply coupon <CouponCode>Grouplauncher20</CouponCode> to get straight
        20% discount on any of the following plans.
      </CouponTitle>

      <ColoredSection>
        Stock of coupons is limited. Grab your one before the time runs out.
      </ColoredSection>

      <Title
        titleText="Choose The Plan That's Suitable For You"
        titleSub="Use Groupboss with your Facebook group and collect unlimited leads."
      />
      <CardContainer />

      <TestimonialIndex
        client={
          <GatsbyImage
            image={data.client.childImageSharp.gatsbyImageData}
            alt="Mark Bowness"
          />
        }
        quote={
          <GatsbyImage
            image={data.quote.childImageSharp.gatsbyImageData}
            alt="quote image"
          />
        }
      />

      <StyledDontWorryContainer>
        <PayementOptionContainer>
          <div>
            <GatsbyImage
              image={data.paypal.childImageSharp.gatsbyImageData}
              alt="PayPal"
            />
          </div>
          <div>
            <GatsbyImage
              image={data.visa.childImageSharp.gatsbyImageData}
              alt="Visa"
            />
          </div>
          <div>
            <GatsbyImage
              image={data.mastercard.childImageSharp.gatsbyImageData}
              alt="Master card"
            />
          </div>
          <div>
            <GatsbyImage
              image={data.discover.childImageSharp.gatsbyImageData}
              alt="Discover Network"
            />
          </div>
          <div>
            <GatsbyImage
              image={data.amex.childImageSharp.gatsbyImageData}
              alt="Amex"
            />
          </div>
        </PayementOptionContainer>
        <div>
          <GatsbyImage
            image={data.moneyback.childImageSharp.gatsbyImageData}
            alt="14 days money back"
          />
        </div>
        <StyledNotWorryContent>
          <StyledNotWorryContentTitle>Do not worry</StyledNotWorryContentTitle>
          <StyledNotWorryContentText>
            You have 14 days of money back guarantee
          </StyledNotWorryContentText>
        </StyledNotWorryContent>
      </StyledDontWorryContainer>

      <VideoContainer>
        <VideoResponsive>
          <StyledIframe
            src="https://www.youtube.com/embed/s7T9oo30N6c"
            title="Collect Emails from your facebook groups and grow email lists like a champ"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </VideoResponsive>
        <VideoResponsive>
          <StyledIframe
            src="https://www.youtube.com/embed/A2fyJuyAi_U"
            title="Collect Emails from your facebook groups and grow email lists like a champ"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </VideoResponsive>
        <VideoResponsive>
          <StyledIframe
            src="https://www.youtube.com/embed/lBJOHwOs2us"
            title="Collect Emails from your facebook groups and grow email lists like a champ"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </VideoResponsive>
      </VideoContainer>

      <SpecialOfferAccordion
        faqImage={
          <GatsbyImage
            image={data.faq.childImageSharp.gatsbyImageData}
            alt="faq art work"
          />
        }
      />
    </>
  )
}

export const query = graphql`
  {
    visa: file(relativePath: { eq: "visa.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 150, height: 78, layout: FIXED)
      }
    }
    paypal: file(relativePath: { eq: "paypal.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 150, height: 78, layout: FIXED)
      }
    }
    mastercard: file(relativePath: { eq: "mastercard.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 150, height: 78, layout: FIXED)
      }
    }
    discover: file(relativePath: { eq: "discover.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 150, height: 78, layout: FIXED)
      }
    }
    amex: file(relativePath: { eq: "amex.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 150, height: 78, layout: FIXED)
      }
    }
    moneyback: file(relativePath: { eq: "moneyback_sticker.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 170, height: 170, layout: FIXED)
      }
    }
    faq: file(relativePath: { eq: "faq.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    quote: file(relativePath: { eq: "quote.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 100, height: 70, layout: FIXED)
      }
    }
    client: file(relativePath: { eq: "mark.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    indexHome: file(relativePath: { eq: "home.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
